import { Alert, Form } from "react-bootstrap";
import * as yup from "yup";
import { Formik, FormikHelpers } from "formik";
import FormGroup from "../../components/FormGroup";
import { FormSelect, FormSelectProps } from "../../components/FormSelect";
import useSWR from "swr";
import { client } from "../../lib/client/client";
import Error from "../../components/Error";
import Loading from "../../components/Loading";
import { useState } from "react";
import LoadingButton from "../../components/LoadingButton";
import FormTextArea from "../../components/FormTextArea";
import MyRequests from "../../components/self-service/MyRequests";
import { BentResponse } from "bent";
import EditParams from "../../components/self-service/EditParams";
import { paramValuesSchema } from "../../lib/client/schemas";
import { Applications, Json, RequestCreation } from "../../lib/api-types";
import type Application from "../../lib/server/models/Application";

export default function SelfService() {
  const { data, error } = useSWR(
    "/api/self-service/applications",
    client<Json<Applications>>("json")
  );

  const [status, setStatus] = useState("ready");
  const [message, setMessage] = useState<string | null>(null);

  if (error) return <Error />;
  if (!data) return <Loading />;

  async function handleSubmit(
    values: RequestCreation,
    actions: FormikHelpers<RequestCreation>
  ) {
    setStatus("loading");
    try {
      const res = await client<BentResponse>(
        "POST",
        204,
        400
      )("/api/self-service/requests", values);
      if (res.statusCode === 400) {
        setStatus("error");
        setMessage(await res.text());
      } else {
        setStatus("success");
      }
      actions.resetForm();
    } catch (error) {
      setStatus("error");
      console.error(error);
    }
  }

  return (
    <>
      <Alert
        show={status === "error" || status === "success"}
        dismissible
        onClose={() => setStatus("ready")}
        variant={status === "error" ? "danger" : "success"}
      >
        {status === "error"
          ? message === "NoManager"
            ? "You do not have a manager listed in Azure AD. We'll resolve this as soon as possible."
            : "An error occurred"
          : "Your request has been submitted! Once your request is approved, you will be notified."}
      </Alert>
      <MyRequests>
        <h2 className="text-center">Access Request</h2>
        <p className="text-center text-muted mb-4">
          Select a request from the dropdown, and click the blue button to
          submit the request.
        </p>
        <Formik
          initialValues={{
            application: "",
            params: {},
            note: "",
          }}
          validationSchema={yup.lazy((value) =>
            yup.object().shape({
              application: yup.string().required("Application is required"),
              params:
                value.application &&
                paramValuesSchema(
                  data.find(({ id }) => id === value.application)?.params,
                  value.params
                ),
              note: yup.string(),
            })
          )}
          onSubmit={handleSubmit}
        >
          {({ handleSubmit, values, isValid, isSubmitting }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <FormGroup<FormSelectProps<Json<Application>, string>>
                as={FormSelect}
                options={data}
                getOptionValue={(x) => x.id ?? ""}
                getOptionLabel={(x) => x.name ?? ""}
                name="application"
                label="Request for"
              />
              {values.application && (
                <EditParams
                  params={
                    data.find(({ id }) => id === values.application)?.params
                  }
                  values={values.params}
                  prefix="params."
                />
              )}
              <FormGroup as={FormTextArea} name="note" label="Note" />
              <LoadingButton
                status={status}
                isSubmitting={isSubmitting}
                isValid={isValid}
                className="w-100"
              >
                Submit Request
              </LoadingButton>
            </Form>
          )}
        </Formik>
      </MyRequests>
    </>
  );
}
