import { Fragment } from "react";
import { Tooltip } from "react-bootstrap";
import ConditionalOverlay from "../ConditionalOverlay";
import { Json } from "../../lib/api-types";
import type Request from "../../lib/server/models/Request";

export interface ApproversProps {
  request: Json<Request>;
}

export default function Approvers({ request }: ApproversProps) {
  return (
    <>
      {request.approvers &&
        Object.entries(request.approvers).map(([id, { actions, name }]) => (
          <Fragment key={id}>
            <p className="mb-0">
              <strong>{name}</strong>
            </p>
            {actions && (
              <ul>
                {Object.entries(actions)
                  .sort((a, b) =>
                    a[1].timestamp && b[1].timestamp
                      ? new Date(a[1].timestamp).getTime() -
                        new Date(b[1].timestamp).getTime()
                      : 0
                  )
                  .map(([action, { timestamp, note }]) => (
                    <li key={action}>
                      <ConditionalOverlay
                        enable={!!note}
                        overlay={
                          <Tooltip id={`${id}.${action}`}>{note}</Tooltip>
                        }
                      >
                        <span>
                          {action.replace(/(\s|^)[a-z]/g, (s) =>
                            s.toUpperCase()
                          )}{" "}
                          {timestamp && new Date(timestamp).toLocaleString()}
                        </span>
                      </ConditionalOverlay>
                    </li>
                  ))}
              </ul>
            )}
          </Fragment>
        ))}
    </>
  );
}
