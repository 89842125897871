import { MouseEventHandler, useState } from "react";
import LoadingButton, { LoadingButtonProps } from "./LoadingButton";

export interface CallbackButtonProps extends Partial<LoadingButtonProps> {
  onClick: MouseEventHandler<HTMLButtonElement>;
}

export default function CallbackButton({
  onClick,
  ...props
}: CallbackButtonProps) {
  const [status, setStatus] = useState("ready");

  return (
    <LoadingButton
      type="button"
      status={status}
      isSubmitting={status === "loading"}
      isValid
      onClick={async (event) => {
        setStatus("loading");
        try {
          await onClick(event);
          setStatus("success");
        } catch (error) {
          setStatus("error");
          console.error(error);
        }
      }}
      {...props}
    />
  );
}
