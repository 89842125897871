import { flattenParams } from "../../lib/utils";
import FormGroup from "../FormGroup";
import { FormMultiSelect, FormMultiSelectProps } from "../FormSelect";
import type Param from "../../lib/server/models/Param";
import { Json } from "../../lib/api-types";
import type { FlatOption } from "../../lib/server/models/FlatParam";

export interface EditParamsProps {
  params: Json<Param>[] | undefined;
  values: { [key: string]: string[] | undefined };
  prefix: string;
}

export default function EditParams({
  params,
  values,
  prefix,
}: EditParamsProps) {
  return (
    <>
      {Array.from(flattenParams(params, values), ([id, param]) =>
        !param.freeForm ? (
          param.options && (
            <FormGroup<FormMultiSelectProps<FlatOption, string>>
              key={id}
              name={`${prefix}${id}`}
              as={FormMultiSelect}
              options={param.options}
              getOptionValue={(x) => x.id ?? ""}
              getOptionLabel={(x) => x.name ?? ""}
              label={param.name ?? ""}
              text={param.text}
            />
          )
        ) : (
          <FormGroup
            key={id}
            name={`${prefix}${id}.0`}
            label={param.name ?? ""}
            text={param.text}
            type={param.freeFormType}
          />
        )
      )}
    </>
  );
}
