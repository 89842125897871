import { Card, Col, Row } from "react-bootstrap";
import useSWR from "swr";
import { client } from "../../lib/client/client";
import Error from "../Error";
import { ReactNode } from "react";
import CallbackButton from "../CallbackButton";
import Approvers from "./Approvers";
import { Json, Requests } from "../../lib/api-types";

export interface MyRequestsProps {
  children: ReactNode;
}

export default function MyRequests({ children }: MyRequestsProps) {
  const { data, error } = useSWR(
    "/api/self-service/requests",
    client<Json<Requests>>("json"),
    { refreshInterval: 1000 }
  );

  if ((!data && !error) || (data && data.length <= 0)) return <>{children}</>;

  async function sendReminder(token: string) {
    await client("POST", 204)(`/api/self-service/requests/${token}/remind`);
  }

  return (
    <Row>
      <Col sm={6} md={8} xl={9} className="mb-4">
        {children}
      </Col>
      <Col sm={6} md={4} xl={3}>
        <h3 className="text-center mb-4">My Requests</h3>
        {error ? (
          <Error />
        ) : (
          data!.map((request) => (
            <Card className="mb-3" key={request.token}>
              <Card.Header>
                <Card.Title>{request.application?.name}</Card.Title>
                {request.timestamp && (
                  <Card.Text>
                    {new Date(request.timestamp).toLocaleString()}
                  </Card.Text>
                )}
              </Card.Header>
              <Card.Body>
                <Approvers request={request} />
                {!request.isClosed && (
                  <CallbackButton
                    size="sm"
                    onClick={() => sendReminder(request.token as string)}
                    className="w-100"
                  >
                    Send Reminder
                  </CallbackButton>
                )}
              </Card.Body>
            </Card>
          ))
        )}
      </Col>
    </Row>
  );
}
