import { OverlayTrigger, OverlayTriggerProps } from "react-bootstrap";

export interface ConditionalOverlayProps extends OverlayTriggerProps {
  enable: boolean;
}

export default function ConditionalOverlay({
  enable,
  children,
  ...props
}: ConditionalOverlayProps) {
  if (!enable) return <>{children}</>;
  return <OverlayTrigger {...props}>{children}</OverlayTrigger>;
}
